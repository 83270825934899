import React from "react"
import Layout from "../components/layout"
import ItemsGrid from "../layouts/ItemsGrid/items-grid.component"
import HeroBanner from "../layouts/HeroBanner/hero-banner.component"
import { graphql, useStaticQuery } from "gatsby"
import CenteredText from "../layouts/CenteredText/centered-text.component"
import FaqsSection from "../layouts/FaqsSection/faqs-section.component"
import GoodStuff from "../layouts/GoodStuff/good-stuff.component"
import ChallengeHero from "../layouts/ChallengeHero/challenge-hero.component"
import LadderGrid from "../layouts/LadderGrid"
import ReasonsGrid from "../layouts/ReasonsGrid"
import WhyJove from "../layouts/WhyJove"
import ImageForm from "../layouts/ImageForm"
// import ImageHeader from "../layouts/ImageHeader"
// import StoreLocatorMap from "../layouts/StoreLocatorMap"
import SecondaryPageImageHeader from "../layouts/SecondaryPageImageHeader"
import HappeningsHeader from "../layouts/HappeningsHeader"

const Layouts = () => {
  const staticQuery = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "about-hero.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      banner: file(relativePath: { eq: "about-banner.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      tryJove: file(relativePath: { eq: "try-jove.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      tryJove2: file(relativePath: { eq: "try-jove-2-bottles.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      goodStuff: file(relativePath: { eq: "good-stuff-guy.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      item1: file(relativePath: { eq: "item1.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      reason1: file(relativePath: { eq: "reason1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      why: file(relativePath: { eq: "why.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      closing: file(relativePath: { eq: "closing-image.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      faqHero: file(relativePath: { eq: "faq-hero.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      challengeHero: file(relativePath: { eq: "challenge-hero.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bottlepack: file(relativePath: { eq: "Jove_Bottle_6Pack_P_v2-min.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  return (
    <Layout seo={{ title: "Layouts - ONLY FOR DEV" }}>
      <SecondaryPageImageHeader title="FAQ" backgroundImage={staticQuery.tryJove2} />
      <ChallengeHero
        title="Hydration Challenge"
        subtitle="Happy, Healthy, Hydrated!"
        content={`<p>Don’t feel sluggish, irritable or have poor sleep, join the Jovē Hydration Challenge. Experience your healthiest, happiest days yet and win prizes!</p>
<p><strong>Enrollment is closed, sign up to get notified when our Summer Hydration Enrollment opens!&nbsp;</strong></p>
<p>Let’s make some Happy. See you in the challenge!</p>`}
        image={staticQuery.challengeHero}
      />
      <LadderGrid
        title="How it Works"
        items={[
          {
            img: staticQuery.item1,
            title: "Drink for 12 Days",
            number: 1,
            content: "It’s just water, right? Umm, no.",
          },
          {
            img: staticQuery.item1,
            title: "Drink for 12 Days",
            number: 2,
            content: "It’s just water, right? Umm, no.",
          },
          {
            img: staticQuery.item1,
            title: "Drink for 12 Days",
            number: 3,
            content: "It’s just water, right? Umm, no.",
          },
        ]}
      />
      <ReasonsGrid
        title="Three sweet reasons to join the Jovē Hydration Challenge."
        items={[
          {
            image: staticQuery.reason1,
            title: "Drink Jovē",
          },
          {
            image: staticQuery.reason1,
            title: "Drink Jovē",
          },
          {
            image: staticQuery.reason1,
            title: "Drink Jovē",
          },
        ]}
      />
      <WhyJove
        title="Why Jovē?"
        items={[
          {
            image: staticQuery.why,
            title: "Increased Hydration",
            subtitle:
              "Cellular hydration is approximately 2/3 of total hydration",
            content:
              "Up to 75% Americans are chronically dehydrated. Feeling irritable, sluggish, having poor sleep and dry skin can all be associated with dehydration. Jovē is clinically shown to provide the cellular hydration you need.",
          },
          {
            image: staticQuery.why,
            title: "Increased Hydration",
            subtitle:
              "Cellular hydration is approximately 2/3 of total hydration",
            content:
              "Up to 75% Americans are chronically dehydrated. Feeling irritable, sluggish, having poor sleep and dry skin can all be associated with dehydration. Jovē is clinically shown to provide the cellular hydration you need.",
          },
          {
            image: staticQuery.why,
            title: "Increased Hydration",
            subtitle:
              "Cellular hydration is approximately 2/3 of total hydration",
            content:
              "Up to 75% Americans are chronically dehydrated. Feeling irritable, sluggish, having poor sleep and dry skin can all be associated with dehydration. Jovē is clinically shown to provide the cellular hydration you need.",
          },
          {
            image: staticQuery.why,
            title: "Increased Hydration",
            subtitle:
              "Cellular hydration is approximately 2/3 of total hydration",
            content:
              "Up to 75% Americans are chronically dehydrated. Feeling irritable, sluggish, having poor sleep and dry skin can all be associated with dehydration. Jovē is clinically shown to provide the cellular hydration you need.",
          },
          {
            image: staticQuery.why,
            title: "Increased Hydration",
            subtitle:
              "Cellular hydration is approximately 2/3 of total hydration",
            content:
              "Up to 75% Americans are chronically dehydrated. Feeling irritable, sluggish, having poor sleep and dry skin can all be associated with dehydration. Jovē is clinically shown to provide the cellular hydration you need.",
          },
        ]}
      />
      <ImageForm
        image={staticQuery.closing}
        content="Sign up for our next Jovē Hydration Challenge later this year. We’ll be get our happy, healthy holiday selves going!"
        title="The Water You’ve Been Waiting For"
      />
      <HeroBanner
        title={`Let’s Talk </br> Details`}
        backgroundImage={staticQuery.faqHero}
      />
      <FaqsSection
        title=""
        faqs={[
          {
            title: "Water Wise",
            items: [
              {
                title: "What is Deep Hydration",
                content: `<p>Deep Hydration<sup>™</sup> occurs when both our body and cells receive the necessary water to support vital functions. Pure water (H<sub>2</sub>0 only) is a neutral substance that has a pH of 7 and rarely, if ever exists in nature. Deep Hydration<sup>™</sup> is water that can effectively pass into our cells, contains a source of available energy in the form of electrons and has a pH higher than 7. In addition, it would not contain harmful contaminants.</p>`,
              },
              {
                title: "What is Deep Hydration",
                content: `<p>Deep Hydration<sup>™</sup> occurs when both our body and cells receive the necessary water to support vital functions. Pure water (H<sub>2</sub>0 only) is a neutral substance that has a pH of 7 and rarely, if ever exists in nature. Deep Hydration<sup>™</sup> is water that can effectively pass into our cells, contains a source of available energy in the form of electrons and has a pH higher than 7. In addition, it would not contain harmful contaminants.</p>`,
              },
              {
                title: "What is Deep Hydration",
                content: `<p>Deep Hydration<sup>™</sup> occurs when both our body and cells receive the necessary water to support vital functions. Pure water (H<sub>2</sub>0 only) is a neutral substance that has a pH of 7 and rarely, if ever exists in nature. Deep Hydration<sup>™</sup> is water that can effectively pass into our cells, contains a source of available energy in the form of electrons and has a pH higher than 7. In addition, it would not contain harmful contaminants.</p>`,
              },
              {
                title: "What is Deep Hydration",
                content: `<p>Deep Hydration<sup>™</sup> occurs when both our body and cells receive the necessary water to support vital functions. Pure water (H<sub>2</sub>0 only) is a neutral substance that has a pH of 7 and rarely, if ever exists in nature. Deep Hydration<sup>™</sup> is water that can effectively pass into our cells, contains a source of available energy in the form of electrons and has a pH higher than 7. In addition, it would not contain harmful contaminants.</p>`,
              },
            ],
          },
          {
            title: "Amazing Science",
            items: [
              {
                title: "What is Deep Hydration",
                content: `<p>Deep Hydration<sup>™</sup> occurs when both our body and cells receive the necessary water to support vital functions. Pure water (H<sub>2</sub>0 only) is a neutral substance that has a pH of 7 and rarely, if ever exists in nature. Deep Hydration<sup>™</sup> is water that can effectively pass into our cells, contains a source of available energy in the form of electrons and has a pH higher than 7. In addition, it would not contain harmful contaminants.</p>`,
              },
              {
                title: "What is Deep Hydration",
                content: `<p>Deep Hydration<sup>™</sup> occurs when both our body and cells receive the necessary water to support vital functions. Pure water (H<sub>2</sub>0 only) is a neutral substance that has a pH of 7 and rarely, if ever exists in nature. Deep Hydration<sup>™</sup> is water that can effectively pass into our cells, contains a source of available energy in the form of electrons and has a pH higher than 7. In addition, it would not contain harmful contaminants.</p>`,
              },
              {
                title: "What is Deep Hydration",
                content: `<p>Deep Hydration<sup>™</sup> occurs when both our body and cells receive the necessary water to support vital functions. Pure water (H<sub>2</sub>0 only) is a neutral substance that has a pH of 7 and rarely, if ever exists in nature. Deep Hydration<sup>™</sup> is water that can effectively pass into our cells, contains a source of available energy in the form of electrons and has a pH higher than 7. In addition, it would not contain harmful contaminants.</p>`,
              },
            ],
          },
          {
            title: "Jovē Love",
            items: [
              {
                title: "What is Deep Hydration",
                content: `<p>Deep Hydration<sup>™</sup> occurs when both our body and cells receive the necessary water to support vital functions. Pure water (H<sub>2</sub>0 only) is a neutral substance that has a pH of 7 and rarely, if ever exists in nature. Deep Hydration<sup>™</sup> is water that can effectively pass into our cells, contains a source of available energy in the form of electrons and has a pH higher than 7. In addition, it would not contain harmful contaminants.</p>`,
              },
              {
                title: "What is Deep Hydration",
                content: `<p>Deep Hydration<sup>™</sup> occurs when both our body and cells receive the necessary water to support vital functions. Pure water (H<sub>2</sub>0 only) is a neutral substance that has a pH of 7 and rarely, if ever exists in nature. Deep Hydration<sup>™</sup> is water that can effectively pass into our cells, contains a source of available energy in the form of electrons and has a pH higher than 7. In addition, it would not contain harmful contaminants.</p>`,
              },
            ],
          },
        ]}
      />
      {/*<Contact />*/}
      <HeroBanner
        title="Joy of Nature. Love of Science."
        backgroundImage={staticQuery.hero}
      />
      <ItemsGrid
        title="Pure Progress in a Bottle"
        items={[
          {
            title: "True Hydration",
            content:
              "We’re here to help you experience the benefits of real hydration; to better yourself, your friends and your community.",
          },
          {
            title: "Pushing Boundaries",
            content:
              "We challenge the beliefs around water; proving and improving how truly healthy water can be.",
          },
          {
            title: "Passionate Progress",
            content:
              "We’re committed to the relentless pursuit of sustainable progress, impeccable science and better health for all.",
          },
        ]}
      />
      <HeroBanner
        subtitle="Better You. Better World."
        content="“When properly hydrated, my body works better.”"
        backgroundImage={staticQuery.banner}
      />
      <CenteredText
        title="Better Science. Better Water."
        content="We believe in the goodness of science and the goodness of water. Science has the power to improve lives, its discoveries can help make changes for the better. Water is essential for life and the foundation of health. We will always use the best science to make the best bottled water."
      />
      {/*<TryJove*/}
      {/*  bgImage={staticQuery.tryJove}*/}
      {/*  title="Try Jovē Now"*/}
      {/*  button={{ text: "Get Jovē Now" }}*/}
      {/*/>*/}

      <GoodStuff
        img={staticQuery.goodStuff}
        items={[
          {
            img: staticQuery.item1,
            title: "Get the Hydration Guide",
            tag: "Hydration",
            content: "It’s just water, right? Umm, no.",
            button: { text: "Learn More" },
            type: "video",
            url:
              "https://secureservercdn.net/45.40.152.13/cnd.527.myftpupload.com/wp-content/uploads/2020/07/062420_JOVE_B-Video-Explainer-Animation.mp4",
          },
          {
            img: staticQuery.item1,
            title: "Get the Hydration Guide",
            tag: "Hydration",
            content: "It’s just water, right? Umm, no.",
            button: { text: "Download" },
            type: "doc",
            url:
              "https://admin-jove.weareshellshock.com/wp-content/uploads/2021/04/April_schedule_SHELLSHOCK.pdf",
          },
          {
            img: staticQuery.item1,
            title: "Get the Hydration Guide",
            tag: "Hydration",
            content: "It’s just water, right? Umm, no.",
            button: { text: "Join Now" },
            type: "link",
            url: "/",
          },
        ]}
        title="The Good Stuff"
      />


      <HappeningsHeader></HappeningsHeader>

    </Layout>
  )
}

export default Layouts
