import styled from "styled-components"
export const CustomContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  .title {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ theme }) => theme.typography.pxToRem(27)};
  }
  h1 {
    font-family: ${({ theme }) => theme.fonts.secondary};
    margin-bottom: 0.25em;
    ${({ theme }) => theme.breakpoints.only("xs")} {
      font-size: 3.1875rem;
    }
  }
  h3 {
    max-width: 460px;
  }
  p {
    max-width: 314px;
  }
`

export const ImageContainer = styled.div`
  border-radius: 50%;
  overflow: hidden;
  margin: 0 0 2em;
  height: 100%;
  width: 100%;
  .gatsby-image-wrapper {
    img {
      object-fit: contain;
    }
  }
`
export const Tag = styled.h3`
  border-bottom: 1px solid #1e1499;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 32px;
  padding-bottom: 5px;
`
