import styled from "styled-components"
import GatsbyBgImage from "../../components/gatsby-bg-image/gatsby-bg-image.component"

export const Wrapper = styled(GatsbyBgImage)`
  height: 40vh;
  padding-top: 20vh;

  h1 {
    text-align: center;
    font-weight: 700;
    font-size: ${({ theme }) => theme.typography.pxToRem(100)};
  }
`
