import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  padding-top: 8em;
  padding-bottom: 8em;
  background-color: white;
  color: ${({ theme }) => theme.palette.primary.main};
  p {
    max-width: 671px;
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  }
  h2,
  h2 p {
    max-width: 380px;
    padding-bottom: 0.5em;
  }
  p,
  h2 {
    text-align: center;
    margin: auto;
  }
  sup {
    top: 0;
  }
`
