import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import Arrow from "../../assets/arrow-1.svg"
import Arrow2 from "../../assets/arrow-2.svg"
export const Wrapper = styled(SectionWrapper)`
  min-height: 718px;
  display: flex;
  align-items: center;
  padding-left: 5em;
  padding-right: 5em;
  background-color: ${({ theme }) => theme.palette.secondary.light};
  color: ${({ theme }) => theme.palette.primary.main};
  .MuiGrid-item {
    position: relative;
  }
  .item-1,
  .item-2,
  .item-0 {
    max-width: 314px;
    h3 {
      max-width: 300px;
    }
  }
  .item-1,
  .item-2 {
    padding-top: 10%;
    div {
      border-radius: 0 !important;
    }
    img {
      object-fit: contain !important;
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    .item {
      align-items: center;
      margin-bottom: 3em;
      text-align: center;
    }
    .item-1,
    .item-2,
    .item-0 {
      padding-top: 0;
    }
    .MuiGrid-container {
      justify-content: center;
      padding-right: 0 !important;
    }
  }
`
export const Underline = styled.div`
  height: 6px;
  display: block;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  margin-bottom: 3em;
`
export const TopContainer = styled.div`
  max-width: 573px;
  margin: auto;
  h2 {
    text-align: center;
  }
`
export const CustomArrow2 = styled(Arrow2)`
  max-height: 120px;
  max-width: 120px;
`
export const CustomArrow = styled(Arrow)`
  max-height: 120px;
  max-width: 120px;
  display: block;
  position: absolute;
  bottom: 6em;
  right: 1em;
`
