import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  min-height: 718px;
  display: flex;
  align-items: center;
  background-color: white;
  color: ${({ theme }) => theme.palette.primary.main};
  h1 {
    max-width: 500px;
    margin: auto;
    padding-bottom: 0.5em;
    line-height: 70px;
  }
  h3 {
    padding-bottom: 0.5em;
    max-width: 263px;
    margin: auto;
  }
  p,
  h1,
  h3 {
    text-align: center;
  }
  p {
    margin: auto;
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
    letter-spacing: 0.15px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    min-height: 500px;
  }
`
