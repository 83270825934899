import React from "react"
import * as S from "./ladder-grid.styles.jsx"
import { Grid, useTheme } from "@material-ui/core"
import Item from "../../components/item/item.component"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const LadderGrid = ({ items, title }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("md"))
  if (!items) return null
  return (
    <S.Wrapper contained maxWidht="md">
      <S.TopContainer>
        <h2>{title}</h2>
        <S.Underline />
      </S.TopContainer>
      <Grid
        container
        justifyContent="space-between"
        style={{ paddingRight: "3em" }}
        spacing={!matches ? 3 : 0}
      >
        {items.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={12}
            md
            key={`items-${index}`}
            className={`item-${index}`}
          >
            {index === 2 && matches && <S.CustomArrow2 />}
            <Item customItem={item} />
            {index === 0 && matches && <S.CustomArrow />}
          </Grid>
        ))}
      </Grid>
    </S.Wrapper>
  )
}
export default LadderGrid
