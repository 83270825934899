import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Dialog } from "@material-ui/core"

export const Wrapper = styled(SectionWrapper)`
  padding-top: calc(${({ theme }) => theme.navHeight}px + 4em);
  padding-bottom: 8em;
  background-color: white;
  h1 {
    border-bottom: 10px solid #1e1499;
    margin-left: 1em;
  }
  p {
    margin-bottom: 1em;
  }
  .MuiGrid-item {
    width: 400px;
  }
`
export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  .gatsby-image-wrapper {
    min-width: 222px;
    img {
      object-fit: contain;
    }
  }
  margin-bottom: 2em;
`
export const CustomDialog = styled(Dialog)`
  button {
    margin-left: auto;
    display: block;
  }
  .MuiPaper-root,
  iframe {
    height: 100%;
    width: 100%;
  }
  .MuiDialogTitle-root {
    padding: 0.5em;
  }
`
export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  video {
    width: 100%;
  }
`
