import React, { useState } from "react"
import * as S from "./good-stuff.styles.jsx"
import { Grid, Zoom } from "@material-ui/core"
import CustomImage from "../../components/custom-image/custom-image.component"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Item from "../../components/item/item.component"
import CustomButton from "../../components/custom-button/custom-button.component"
import parse from "html-react-parser"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom style={{ transitionDelay: "100ms" }} ref={ref} {...props} />
})
const GoodStuff = ({ img, items, title }) => {
  const isIos = typeof navigator !== 'undefined' && 
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1))
  const handleClick = (type, url) => {
    if (type && url) {
      setObjectToShow(getObjectToShow(type, url))
      setIsOpen(true)
    }
  }
  const handleClose = () => {
    setIsOpen(false)
  }
  const [objectToShow, setObjectToShow] = useState("")

  const getObjectToShow = (type, url) => {
    switch (type) {
      case "doc":
        return <iframe title="good-suff" src={url} type="application/pdf" />
      case "video":
        return <video autoPlay loop playsInline controls src={url} />
      default:
        break
    }
  }
  const [isOpen, setIsOpen] = useState(false)

  if (!items) return null
  return (
    <>
      <S.Wrapper contained>
        <Grid container justifyContent="center" alignItems="center" spacing={5}>
          <Grid item sm={12}>
            <S.TopContainer>
              <CustomImage img={img} alt="" />
              {title && <h1>{parse(title)}</h1>}
            </S.TopContainer>
          </Grid>
          {items.map((item, index) => (
            <Grid item key={`items-${index}`}>
              <Item customItem={item} />
              <CustomButton
                className="darkButton"
                onClick={e => handleClick(item.type, item.url)}
                target={isIos && item.type ? "_blank" : ""}
                href={
                  item.type === "link"
                    ? item.button.url
                    : isIos && item.type === "doc"
                    ? item.url
                    : ""
                }
              >
                {item.button && item.button.title}
              </CustomButton>
            </Grid>
          ))}
        </Grid>
      </S.Wrapper>
      <S.CustomDialog
        open={isOpen}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <MuiDialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <S.Content>{objectToShow}</S.Content>
      </S.CustomDialog>
    </>
  )
}
export default GoodStuff
