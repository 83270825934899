import React from "react"
import * as S from "./items-grid.styles.jsx"
import { Grid } from "@material-ui/core"
import CustomTypography from "../../components/custom-typography/custom-typography.component"

const ItemsGrid = ({ items, title }) => {
  if (!items) return null
  return (
    <S.Wrapper contained>
      <h1>{title}</h1>
      <Grid container justifyContent="center" spacing={2}>
        {items.map((item, index) => (
          <Grid item md={4} key={`items-${index}`}>
            {item.title && <h3>{item.title}</h3>}
            {item.content && (
              <CustomTypography>{item.content}</CustomTypography>
            )}
          </Grid>
        ))}
      </Grid>
    </S.Wrapper>
  )
}
export default ItemsGrid
