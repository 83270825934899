import React from "react"
import * as S from "./centered-text.styles.jsx"
import CustomTypography from "../../components/custom-typography/custom-typography.component"
import parse from "html-react-parser"
const CenteredText = ({ title, content }) => {
  if (!content) return null
  return (
    <S.Wrapper contained>
      {title && <h2>{parse(title)}</h2>}
      {content && <CustomTypography>{parse(content)}</CustomTypography>}
    </S.Wrapper>
  )
}
export default CenteredText
