import React from "react"
import Container from "@material-ui/core/Container"
import * as S from "./secondary-page-image-header.styles.jsx"

const SecondaryPageImageHeader = ({title, backgroundImage, height}) => {
  const headerHeight = height ? height : '40vh';

  return (
    <S.Wrapper img={backgroundImage} style={{ height: headerHeight }} alt="">
      <Container>
        <h1>{title}</h1>
      </Container>
    </S.Wrapper>
  )
}
export default SecondaryPageImageHeader
