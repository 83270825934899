import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Wrapper = styled(SectionWrapper)`
    background-color: #fff;
    position: relative;
    padding-top: 0;

    #hero_bg_mobile { display: none; }

    [data-placeholder-image] {
        background-color: transparent !important;
    }

    #happenings_circle {
        position: absolute;
        z-index: 3;
        top: 15vw;
        right: 10vw;

        width: 30vw;
    }

    ${({ theme }) => theme.breakpoints.down("sm")} {
        #hero_bg_desktop { display: none; }
        #hero_bg_mobile { display: block; }

        #happenings_circle {
            width: 40vw;
            right: unset;
            top: unset;
            bottom: 5vw;
            left: 3vw;
        }
    }
`

export const HeaderImageLarge = styled(CustomImage)`
    position: absolute;
    top: -50px;
    left: 0;
    width: 100vw;
    mask-image: url(/img/gradient-wave-lg.svg);
    mask-repeat: no-repeat;
    mask-position: 0 50px;

    ${({ theme }) => theme.breakpoints.down("sm")} {
        mask-image: unset;
        display: none;
    }
`

export const HeaderImageSmall = styled(CustomImage)`
    display: none;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 80vw;
    width: 100%;


    ${({ theme }) => theme.breakpoints.down("sm")} {
        display: block;
    }
`