import React from "react"
import * as S from "./item.styles.jsx"
import CustomImage from "../custom-image/custom-image.component"
import CustomTypography from "../custom-typography/custom-typography.component"

const Item = customItem => {
  return (
    <S.CustomContainer className="item">
      <S.ImageContainer>
        {customItem.customItem.img && (
          <CustomImage
            alt=""
            img={customItem.customItem.img}
            arPaddingPercentage={100}
          />
        )}
      </S.ImageContainer>
      {customItem.customItem.tag && <S.Tag>{customItem.customItem.tag}</S.Tag>}
      {customItem.customItem.number && <h1>{customItem.customItem.number}</h1>}
      {customItem.customItem.title && (
        <h3 className="title">{customItem.customItem.title}</h3>
      )}
      {customItem.customItem.content && (
        <CustomTypography fontSize={1.25}>
          {customItem.customItem.content}
        </CustomTypography>
      )}
    </S.CustomContainer>
  )
}
export default Item
