import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as S from "./happenings-header.styles.jsx"
import GradientWaveLarge from "./../../assets/gradient-wave-lg.svg";
import GradientWaveSmall from "./../../assets/gradient-wave-sm.svg";
import HappeningsCircle from "./../../assets/happenings-events-blog.svg"

const HappeningsHeader = () => {
  const staticQuery = useStaticQuery(graphql`
    query {
      bottlesDesktop: file(relativePath: { eq: "happening-bottles-desktop.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      bottlesMobile: file(relativePath: { eq: "happening-bottles-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)

  return (
    <S.Wrapper containerClassName="container">
      <GradientWaveLarge id="hero_bg_desktop" className="backing_img" />
      <GradientWaveSmall id="hero_bg_mobile" className="backing_img" />
      <HappeningsCircle id="happenings_circle" />

      <S.HeaderImageLarge img={staticQuery.bottlesDesktop}/>
      <S.HeaderImageSmall img={staticQuery.bottlesMobile} />
    </S.Wrapper>
  )
}
export default HappeningsHeader